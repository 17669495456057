<template>
  <div class="HomepageBestSellersSlider my-9 text-center">
    <h3 class="heading-4">{{ $t('77-signature-pieces', 'The 77 Signature') }}</h3>
    <h5 class="body-12-aa mb-6">
      {{
        $t('77-signature-pieces-subtitle-updated', 'Discover iconic designs by our in-house team of master jewellers.')
      }}
    </h5>

    <div class="best-sellers-slider md:container" data-cy="best-sellers">
      <UiSwiper :config="swiperConfig" class="slider-slide">
        <SwiperSlide v-for="product in products" :key="product.ItemId">
          <NuxtLink :to="stripBaseUrl(product.BaseUrl)" class="no-underline">
            <img :src="product.Image.Path" :alt="product.Image.Alt" />
            <h4 class="body-14-aa-cap">{{ product.Name }}</h4>

            <p class="body-14-aa mb-0">
              <template v-if="product.ItemPrice.HasDiscount">
                <span v-if="currentLanguage.Id !== LANGUAGES.CHINESE" class="mr-1">{{ $t('from', 'From') }}</span>
                <span class="price discountedPrice">
                  {{ removeSpaces(product.ItemPrice.TotalPrice.Price.WebPrice.WithVat) }}
                </span>
                <span v-if="product.ItemPrice.TotalPrice.DiscountedPrice" class="ml-1 saving">
                  {{ removeSpaces(product.ItemPrice.TotalPrice.DiscountedPrice.WebPrice.WithVat) }}
                </span>
              </template>

              <span v-else class="price">
                {{ removeSpaces(product.ItemPrice.TotalPrice.FinalPrice.WebPrice.WithVat) }}
              </span>
            </p>
          </NuxtLink>
        </SwiperSlide>
      </UiSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchProductsByConfig } from '~/api/product'
import type { BestSellerSwiperSignature } from '@/constants/swiper/home'
import { bestSellerSwiperOptions } from '@/constants/swiper/home'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { bestSellerSwiperConfigs } from '~/constants/home'
// constants
import { LANGUAGES } from '~/constants/languages'

const { currentCountry, currentCurrency, currentLanguage } = storeToRefs(useNewSettingsStore())

const products: Ref<BestSellerSwiperSignature[]> = ref([])

defineProps({
  swiperConfig: {
    type: Object,
    default: () => bestSellerSwiperOptions,
  },
})

const loadBestSellerProducts = async () => {
  const response = await fetchProductsByConfig({
    countryId: currentCountry.value?.Id,
    currencyId: currentCurrency.value?.Id,
    languageId: currentLanguage.value?.Id,
    configs: bestSellerSwiperConfigs,
  })
  products.value = response?.Products || []
}

const removeSpaces = (msg: string) => msg.replace(' ', '')

useAsyncData(
  'best-seller',
  async () => {
    await loadBestSellerProducts()
  },
  {
    watch: [() => currentCountry.value?.Id, () => currentCurrency.value?.Id, () => currentLanguage.value?.Id],
  },
)
</script>

<style>
.best-sellers-slider .slider-slide {
  min-width: 146px;
}

.best-sellers-slider .slider-slide a {
  display: block;
  background: #fafafa;
  padding: 16px 4px 24px;
  margin: 0 8px;
}

.best-sellers-slider .slider-slide img {
  height: auto;
  mix-blend-mode: darken;
  aspect-ratio: 4/3;
  object-fit: cover;
  transition: transform 0.2s;
}

.best-sellers-slider .slider-slide img:hover {
  transform: scale(1.2);
}

.best-sellers-slider .discountedPrice {
  text-decoration: line-through;
}
.best-sellers-slider .saving {
  color: #ab0000;
  font-family: 'Nunito Sans SemiBold';
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.82px;
  line-height: 24px;
}
</style>
