import { METALS } from './metals'
import { SHAPES } from './shapes'

export const bestSellerSwiperConfigs = [
  {
    Config: {
      ItemId: 739,
      MetalId: METALS.WHITE_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },

  {
    Config: {
      ItemId: 379,
      CategoryId: -1,
      MetalId: METALS.YELLOW_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },

  {
    Config: {
      ItemId: 409,
      MetalId: METALS.WHITE_GOLD,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 104,
    },
  },

  {
    Config: {
      ItemId: 180,
      MetalId: METALS.WHITE_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },

  {
    Config: {
      ItemId: 851,
      CategoryId: -1,
      MetalId: METALS.WHITE_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },

  {
    Config: {
      ItemId: 497,
      MetalId: METALS.WHITE_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },

  {
    Config: {
      ItemId: 396,
      MetalId: METALS.WHITE_GOLD,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 97,
    },
  },

  {
    Config: {
      ItemId: 349,
      CategoryId: -1,
      MetalId: METALS.WHITE_GOLD,
      ShapeId: SHAPES.ROUND,
      StoneType: null,
      GemType: null,
      BandPreference: null,
      VariationId: 0,
    },
  },
]

export const showcaseSectionItems = [
  {
    title: 'Handcrafted Designs',
    icon: 'icon77-crafted',
    description: 'Design a jewel in three simple steps to have it custom-created by experts.',
  },
  {
    title: 'Expert Advice',
    icon: 'icon77-advice',
    description: 'Chat with us online, by phone, or book an appointment in one of our showrooms.',
  },
  {
    title: '30 Day Returns',
    icon: 'icon77-cert',
    description: 'Return your item in under 30 days, or have your ring resized for the perfect fit.',
  },
  {
    title: 'Sourcing With Care',
    icon: 'icon77-sourced',
    description: 'Explore our selection of independently certified and responsibly sourced gems.',
  },
]
export const getShowcaseSectionItems = (i18nTranslation: any) => [
  {
    title: i18nTranslation('showcase-title-1', 'Handcrafted Designs'),
    icon: 'icon77-crafted',
    description: i18nTranslation(
      'showcase-text-1-short-new',
      'Create your dream jewel in three simple steps, and have your unique design custom-created by experts.',
    ),
  },
  {
    title: i18nTranslation('showcase-title-2', 'Expert Advice'),
    icon: 'icon77-advice',
    description: i18nTranslation(
      'showcase-text-2-short',
      'Chat with us online, by phone, or book an appointment in one of our showrooms.',
    ),
  },
  {
    title: i18nTranslation('showcase-title-3', '30 Day Free Returns'),
    icon: 'icon77-cert',
    description: i18nTranslation(
      'showcase-text-3-short-new',
      'Return your item in under 30 days, or have your ring resized for the perfect fit.',
    ),
  },
  {
    title: i18nTranslation('showcase-title-4', 'Sourced With Care'),
    icon: 'icon77-sourced',
    description: i18nTranslation(
      'showcase-text-4-short',
      'Explore our selection of independently certified and responsibly sourced gems.',
    ),
  },
]

export const getDiamondSectionItems = (i18nTranslation: any, translateShopAspRouteToFriendlyUrl: any) => [
  {
    title: i18nTranslation('diamonds'),
    description: i18nTranslation('homepage-diamonds-section-text-new'),
    ctaLinks: [
      {
        to: translateShopAspRouteToFriendlyUrl('/shopnew/default.aspx?category=7&subcategory=14'),
        text: i18nTranslation('homepage-browse-diamonds', 'Shop Diamonds'),
      },
    ],
    ctaCategory: 'Diamonds',
    ctaDiId: 'di-id-bb365e4a-b5ce80b0',
    alt: 'Diamonds',
    path: '/images/Homepage/',
    imageXL: '4Tiles/XL-L_diam-4x3_x{ratio}.jpg',
    imageS: '4Tiles/S_diam-4x3_x{ratio}.jpg',
    imageM: '4Tiles/M_diam-4x3_x{ratio}.jpg',
  },
  {
    title: i18nTranslation('gemstones'),
    description: i18nTranslation('homepage-gemstones-rgs-section-text-new'),
    ctaLinks: [{ to: '/gemstones', text: i18nTranslation('shop-gemstones') }],
    ctaCategory: 'Gemstones',
    ctaDiId: 'di-id-dfc77fd7-669ed0bc',
    alt: 'Gemstones',
    path: '/images/Homepage/',
    imageXL: '4Tiles/XL-L_gem-4x3_x{ratio}.jpg',
    imageS: '4Tiles/S_gem-4x3_x{ratio}.jpg',
    imageM: '4Tiles/M_gem-4x3_x{ratio}.jpg',
  },
  {
    title: i18nTranslation('cupids-ideal-lg-diamonds', "Cupid's Ideal Lab-Grown"),
    description: i18nTranslation(
      'cupids-ideal-lg-diamonds-text1',
      '77’s signature cut, now available in laboratory-grown diamonds. Experience the height of symmetry with advanced diamond technology.',
    ),
    ctaLinks: [
      {
        to: translateShopAspRouteToFriendlyUrl('/shopnew/default.aspx?category=7&subcategory=14&cut=510&stone=3'),
        text: i18nTranslation('shop-now'),
      },
      {
        to: '/cupids-ideal-lab-grown-diamonds',
        text: i18nTranslation('lg-cupid-what-makes-them-unique', 'What makes them Unique?'),
      },
    ],
    ctaCategory: "Cupid's Ideal Lab-Grown",
    ctaDiId: 'di-id-ef614c04-453479c6',
    alt: "Cupid's Ideal Lab-Grown",
    path: '/images/Homepage/',
    imageXL: '4Tiles/XL-L_dia-cupid-lab-new24_x{ratio}.jpg',
    imageS: '4Tiles/S_dia-cupid-lab-new24_x{ratio}.jpg',
    imageM: '4Tiles/M_dia-cupid-lab-new24_x{ratio}.jpg',
  },
  {
    title: i18nTranslation('cupids-ideal-diamonds'),
    description: i18nTranslation('cupids-ideal-hero-banner-text1'),
    ctaLinks: [
      {
        to: translateShopAspRouteToFriendlyUrl('/shopnew/default.aspx?category=7&subcategory=14&cut=510'),
        text: i18nTranslation('shop-cupids-ideal'),
      },
      { to: '/cupids-ideal-diamonds', text: i18nTranslation('what-makes-them-ideal') },
    ],
    ctaCategory: "Cupid's Ideal Diamonds",
    ctaDiId: 'di-id-a5af0d62-444c39be',
    alt: "Cupid's Ideal Diamonds",
    path: '/images/Homepage/',
    imageXL: '4Tiles/XL-L_diam-cup-4x3_x{ratio}.jpg',
    imageS: '4Tiles/S_diam-cup-4x3_x{ratio}.jpg',
    imageM: '4Tiles/M_diam-cup-4x3_x{ratio}.jpg',
  },
]
